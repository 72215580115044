import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import style from './forms.mod.scss'
import FormsContact from './forms-contact'
import smoothscroll from 'smoothscroll-polyfill'

const Form = ({
  successMessage = 'Thank you for your message, we will be in touch shortly.',
  formColor = 'Primary',
  formType,
  formName
}) => {
  const [formState, setFormState] = useState('ready')

  const modifiers = [
    `${style.form}--color-${formColor.toLowerCase().replace(/ /gi, '-')}`
  ]

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  const handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById('contactForm')
    let formData = new FormData(myForm)
    setFormState('loading')
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString()
    })
      .then(response => {
        if (response.status === 200) onSuccess(response)
        console.log(response)
      })
      .catch(error => {
        setFormState('error')
        console.log(error)
      })

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  const onSuccess = response => {
    console.log('Form successfully submitted', response)
    setFormState('success')
  }

  return (
    <form
      id="contactForm"
      name={formName}
      method="POST"
      data-netlify="true"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value={formName} />
      <div className={[style.form, ...modifiers].join(' ')}>
        {formState !== 'error' &&
          formState !== 'success' &&
          formType === 'Contact' && (
            <FormsContact formColor={formColor.toLowerCase()} />
          )}
        {formState === 'error' && (
          <div className={style.form__error}>
            <p>{'Something went wrong. Please try again later.'}</p>
          </div>
        )}
        {formState === 'success' && (
          <div className={style.form__success}>
            <p>{successMessage}</p>
          </div>
        )}
      </div>
      {/* Misc Tracking fields */}
      <input type="hidden" name="type" value={formType} />
    </form>
  )
}

export default Form

export const query = graphql`
  fragment Form on DatoCmsForm {
    id
    model {
      apiKey
    }
    background
    formType
    formColor
    formName
    paddingTop
    paddingBottom
    __typename
  }
`
