import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import style from './image.mod.scss'

const Image = ({
  image,
  elevated = false,
  width = 'medium',
  modifiers = []
}) => {
  elevated && modifiers.push(style[`image--elevated`])
  width &&
    modifiers.push(
      style[
        `image--width-${width
          .trim()
          .toLowerCase()
          .replace(/ /gi, '-')}`
      ]
    )

  const { gatsbyImageData, alt } = image

  return (
    <div className={[style.image, ...modifiers].join(' ')}>
      {image && (
        <div className={style.image__wrap}>
          <GatsbyImage image={gatsbyImageData} alt={alt} />
        </div>
      )}
    </div>
  )
}

export default Image

export const query = graphql`
  fragment Image on DatoCmsImage {
    background
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "545", fit: "crop", w: "970", q: 60 }
      )
      alt
    }
    model {
      apiKey
    }
    paddingBottom
    paddingTop
    __typename
    width
  }
`
