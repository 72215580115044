import React, { useRef } from 'react'
import style from './footer.mod.scss'
import { Button } from '@components'
import Facebook from '@svgs/facebook.svg'
import { useStaticQuery, graphql } from 'gatsby'
import Instagram from '@svgs/instagram.svg'

const Footer = () => {
  const classes = [style.footer]
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook
          instagram
          privacy
          terms
          contact
        }
      }
    }
  `)

  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className={classes.join(' ')}>
      <div className={style.footer__container}>
        <div className={style.footer__inner}>
          <div className={style.footer__info}>
            <p>{`© ${year} HC Group - Gatsby Landing Site`}</p>
            <p>
              {'Designed and Developed by'}{' '}
              <Button
                unstyled
                to={'https://www.harveycameron.nz/'}
                children={'Harvey Cameron'}
              />
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
