import React from 'react'
import { graphql, Link } from 'gatsby'
import style from './card.mod.scss'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Button } from '@components'

const CardLinkWrapper = ({ children, to, ...props }) => {
  const isExternal = (to && to.indexOf('http') > -1) || false

  if (!to) {
    return <>{children}</>
  } else if (isExternal) {
    return (
      <a target="_blank" href={to} {...props}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    )
  }
}

const Card = props => {
  const {
    backgroundColour,
    bordered = false,
    button,
    contained = false,
    description,
    elevated = false,
    heading,
    horizontal = false,
    image,
    horizontalImage,
    modifiers = []
  } = props
  const { gatsbyImageData, alt } = horizontal ? horizontalImage : image

  backgroundColour &&
    modifiers.push(
      `${
        style.card
      }--background-colour-${backgroundColour
        .toLowerCase()
        .replace(/ /gi, '-')}`
    )
  bordered && modifiers.push(`${style.card}--bordered`)
  contained && modifiers.push(`${style.card}--contained`)
  elevated && modifiers.push(`${style.card}--elevated`)
  horizontal && modifiers.push(`${style.card}--horizontal`)

  return (
    <CardLinkWrapper to={button?.link?.path || button.url}>
      <div className={[style.card, ...modifiers].join(' ')}>
        <div className={style.card__image}>
          <GatsbyImage image={gatsbyImageData} alt={alt} />
        </div>
        <div className={style.card__content}>
          {heading && <h4>{heading}</h4>}
          {description && <p>{description}</p>}
          {(button?.link?.path || button?.url) && (
            <Button to={button?.link?.path || button.url}>
              {button.displayText}
            </Button>
          )}
        </div>
      </div>
    </CardLinkWrapper>
  )
}

export default Card

export const query = graphql`
  fragment Card on DatoCmsCard {
    button {
      ...Link
    }
    description
    heading
    id
    image {
      gatsbyImageData(
        layout: FULL_WIDTH
        aspectRatio: 1.4
        imgixParams: { h: "400", w: "600", fit: "crop", q: 60 }
      )
      alt
    }
    horizontalImage: image {
      gatsbyImageData(
        layout: FULL_WIDTH
        imgixParams: { h: "800", w: "800", fit: "crop", q: 60 }
      )
      alt
    }
  }
`
