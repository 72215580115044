import React, { useState } from 'react'
import style from './checkbox.mod.scss'
import { BsSquareFill, BsSquare, BsCheck } from 'react-icons/bs'

const Checkbox = React.forwardRef(
  ({ label, name, placeholder, required, inverted = false }, ref) => {
    const [isChecked, setChecked] = useState(false)

    return (
      <div className={style.checkbox}>
        <input
          type="hidden"
          name={name}
          disabled={isChecked}
          value={!inverted ? 'No' : 'Yes'}
        />
        <input
          type="checkbox"
          checked={isChecked}
          ref={ref}
          id={`checkbox--${name}`}
          name={name}
          placeholder={placeholder}
          required
          onChange={() => setChecked(!isChecked)}
          value={!inverted ? 'Yes' : 'No'}
        />
        {label && (
          <label htmlFor={`checkbox--${name}`}>
            {isChecked ? (
              <span className={style.checkbox__checked}>
                <BsSquareFill />
                <div className={style.checkbox__check}>
                  <BsCheck />
                </div>
              </span>
            ) : (
              <BsSquare />
            )}
            {label}
            {required && <span aria-label="required">*</span>}
          </label>
        )}
      </div>
    )
  }
)

export default Checkbox
