import React from 'react'
import style from './forms.mod.scss'
import { Input, Textarea, Checkbox, Button } from '@components'

const FormsContact = ({ formColor }) => {
  return (
    <>
      <div className={style.form__double}>
        <Input
          type="text"
          label="First Name"
          placeholder="Enter first name"
          name="firstName"
          required
        />
        <Input
          type="text"
          label="Last Name"
          placeholder="Enter last name"
          name="lastName"
          required
        />
      </div>
      <Input
        type="email"
        label="Email Address"
        placeholder="Enter your email address"
        name="email"
        required
      />
      <Input
        type="text"
        label="Subject"
        placeholder="I'm enquiring about..."
        name="subject"
        required
      />
      <Textarea
        label="Message"
        placeholder="Enter your message here"
        required
        name="message"
      />
      <Checkbox
        label="Please subscribe me to the weekly newsletter to include regular updates and motivation."
        name="checkbox"
      />
      <div className={style.form__submit}>
        <Button type="submit" color={formColor}>
          {'Send Enquiry'}
        </Button>
      </div>
    </>
  )
}

export default FormsContact
