import React from 'react'
import LogoSvg from '@svgs/logo.svg'
import { Link } from 'gatsby'

const Logo = ({ modifiers = [] }) => {
  return (
    <Link to="/" aria-label={`Home`}>
      <div className={[...modifiers].join(' ')}>
        <div>
          <LogoSvg />
        </div>
      </div>
    </Link>
  )
}

export default Logo
