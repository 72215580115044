import React from 'react'
import { Block, Carousel, Card } from '@components'

const DatoCmsCardSlider = ({
  paddingTop,
  paddingBottom,
  background,
  backgroundStyle,
  ...props
}) => {
  return (
    <Block
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      background={background}
      backgroundStyle={backgroundStyle}
    >
      <Carousel
        type="padding-bottom"
        scaleSlides
        config={{
          centeredSlides: true,
          spaceBetween: 40,
          navigation: false,
          slidesPerView: 2,
          initialSlide: 1,
          breakpoints: {
            320: {
              slidesPerView: 1
            },
            1024: {
              slidesPerView: 2
            },
            1800: {
              slidesPerView: 3
            }
          }
        }}
        {...props}
      >
        {props.cards.map((slide, index) => {
          return (
            <Card
              horizontal
              contained
              elevated
              key={`card-slide-${props.id}-${slide.id}`}
              {...slide}
            />
          )
        })}
      </Carousel>
    </Block>
  )
}

export default DatoCmsCardSlider
