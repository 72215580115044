import React, { Fragment } from 'react'
import style from './button.mod.scss'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PropTypes from 'prop-types'

const ButtonContent = ({ children }) => (
  <Fragment>
    <span className={style.button__text}>{children}</span>
  </Fragment>
)

const Button = ({
  activeClass = '',
  alignment = 'left',
  buttonStyle = 'primary',
  children,
  color = 'default',
  disabled = false,
  elevated = false,
  gaTarget,
  isMenuItem = false,
  modifiers = [],
  onClick,
  size = 'medium',
  title,
  to = '/',
  type = null,
  unstyled = false,
  ...props
}) => {
  const data = useStaticQuery(graphql`
    query SiteUrlQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  // Align the button
  modifiers.push(
    `${style.button}--alignment-${alignment.toLowerCase().replace(/ /gi, '-')}`
  )
  // Sets the main color of the button - background/outline/text
  modifiers.push(
    `${style.button}--color-${color.toLowerCase().replace(/ /gi, '-')}`
  )
  // Sets the vertical padding
  modifiers.push(
    `${style.button}--size-${size.toLowerCase().replace(/ /gi, '-')}`
  )
  // If elevated is true this will add a shadow
  elevated && modifiers.push(`${style.button}--elevated`)
  // Sets the button style
  modifiers.push(
    `${style.button}--style-${buttonStyle.toLowerCase().replace(/ /gi, '-')}`
  )
  // Used in menu item component
  isMenuItem && modifiers.push(`${style.button}--menu-item`)

  const { siteUrl } = data.site.siteMetadata

  const externalLink =
    to.search(/^tel:/g) > -1 ||
    to.search(/^mailto:/g) > -1 ||
    (to.search(/http/g) > -1 && to.search(siteUrl) === -1)

  const config = {
    className: unstyled
      ? [style['button--unstyled'], ...modifiers].join(' ')
      : [style.button, ...modifiers].join(' '),
    onClick
  }

  // Strip the siteURL from the to field to create a relative path for <Link />
  if (!externalLink && !type && to.search(siteUrl) === -1)
    to.replace(siteUrl, '')

  // Set activeClassName for <Link />
  if (!externalLink && !type && activeClass)
    config.activeClassName = style[`button--${activeClass}`]

  // Set ariaLabel for <button />
  if (title && type) config.ariaLabel = title

  // Set title for <a /> and <Link />
  if (title && !type) config.title = title

  if (disabled) config.disabled = 'disabled'
  if (gaTarget) config['data-ga-target'] = gaTarget

  if (externalLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...config}>
        <ButtonContent children={children} />
      </a>
    )
  } else if (type) {
    return (
      <button type={type} {...config}>
        <ButtonContent children={children} />
      </button>
    )
  } else {
    return (
      <Link to={to} {...config} {...props}>
        <ButtonContent children={children} />
      </Link>
    )
  }
}

export default Button

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  modifiers: PropTypes.array
}

export const query = graphql`
  fragment Link on DatoCmsLink {
    id
    displayText
    url
    link {
      ... on DatoCmsHome {
        id
        path
      }
      ... on DatoCmsPage {
        id
        path
      }
      ... on DatoCmsPost {
        id
        path
      }
    }
    __typename
  }

  fragment Button on DatoCmsButton {
    id
    paddingTop
    paddingBottom
    background
    alignment
    buttonStyle
    size
    color
    model {
      apiKey
    }
    link {
      ...Link
    }
    __typename
  }
`
