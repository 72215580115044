import React, { useState } from 'react'
import { graphql } from 'gatsby'
import style from './accordion.mod.scss'
import { motion } from 'framer-motion'
import { Content } from '@components'
import Arrow from '@svgs/arrow.svg'

const Accordion = props => {
  const [expanded, setExpanded] = useState(0)

  return (
    <div className={style.accordion}>
      {props.item.map(({ title, description }, i) => {
        const isOpen = i === expanded
        return (
          <div className={style.accordion__item} key={`accordion-item-${i}`}>
            <a
              href={`#accordion-item-${i}`}
              onClick={e => {
                e.preventDefault()
                setExpanded(isOpen ? false : i)
              }}
              className={style.accordion__header}
            >
              <h4>{title}</h4>
              <motion.span
                animate={isOpen ? 'open' : 'collapsed'}
                initial={'collapsed'}
                variants={{
                  open: { rotate: 180 },
                  collapsed: { rotate: 0 }
                }}
                className={style.accordion__arrow}
              >
                <span className={style.accordion__icon}>
                  <Arrow />
                </span>
              </motion.span>
            </a>

            <motion.div
              layoutTransition
              useInvertedScale
              key={`accordion-item-motion-${i}`}
              id={`accordion-item-${i}`}
              animate={isOpen ? 'open' : 'collapsed'}
              initial={'collapsed'}
              variants={{
                open: { height: '100%', opacity: 1 },
                collapsed: {
                  height: '0',
                  opacity: 0,
                  transition: {
                    opacity: {
                      duration: 0.2
                    }
                  }
                }
              }}
            >
              {description && (
                <div className={style.accordion__content}>
                  <Content content={description} />
                </div>
              )}
            </motion.div>
          </div>
        )
      })}
    </div>
  )
}

export default Accordion

export const query = graphql`
  fragment Accordion on DatoCmsAccordion {
    id
    model {
      apiKey
    }
    paddingTop
    paddingBottom
    background
    backgroundStyle
    __typename
    item {
      title
      description
    }
  }
`
